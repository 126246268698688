body{
  margin: 0;
  padding: 0;
  font-family: Sarabun;
  color: #061126;
}
.h-100vh{
  min-height: 100vh;
}
.img-fit{
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.card-login-st-1{
  border:0;
  max-width: 509px;
  margin:0 auto;
  width: 100%;
  height: 691px;
}
.card-login-st-1 .card-body{
  padding-left: 80px;
  padding-right: 80px;
}
.form-st-1{
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  height: 43.6px;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid rgba(76, 78, 100, 0.22);
  background: #FFF;
}

.btn-change-type-password{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M11.9999 16.8426C9.60992 16.8426 7.66992 14.9026 7.66992 12.5126C7.66992 10.1226 9.60992 8.18262 11.9999 8.18262C14.3899 8.18262 16.3299 10.1226 16.3299 12.5126C16.3299 14.9026 14.3899 16.8426 11.9999 16.8426ZM11.9999 9.68262C10.4399 9.68262 9.16992 10.9526 9.16992 12.5126C9.16992 14.0726 10.4399 15.3426 11.9999 15.3426C13.5599 15.3426 14.8299 14.0726 14.8299 12.5126C14.8299 10.9526 13.5599 9.68262 11.9999 9.68262Z" fill="%23B6BBC6"/><path d="M12.0001 21.5326C8.24008 21.5326 4.69008 19.3326 2.25008 15.5126C1.19008 13.8626 1.19008 11.1726 2.25008 9.51255C4.70008 5.69255 8.25008 3.49255 12.0001 3.49255C15.7501 3.49255 19.3001 5.69255 21.7401 9.51255C22.8001 11.1626 22.8001 13.8526 21.7401 15.5126C19.3001 19.3326 15.7501 21.5326 12.0001 21.5326ZM12.0001 4.99255C8.77008 4.99255 5.68008 6.93255 3.52008 10.3226C2.77008 11.4926 2.77008 13.5326 3.52008 14.7026C5.68008 18.0926 8.77008 20.0326 12.0001 20.0326C15.2301 20.0326 18.3201 18.0926 20.4801 14.7026C21.2301 13.5326 21.2301 11.4926 20.4801 10.3226C18.3201 6.93255 15.2301 4.99255 12.0001 4.99255Z" fill="%23B6BBC6"/></svg>');
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 13px;
  background-color: transparent;
  border: 0;
}

input[type="text"] + .btn-change-type-password:after{

	content: "";
    width: 26px;
    height: 26px;
    position: absolute;
    transform: rotate(30deg);
    border-top: 2px solid #B6BBC6;
    margin-left: -18px;
    margin-top: -2px

}

.btn.btn-change-type-password:hover {
  color: #FFF;
  background-color: transparent;
  border-color: transparent;
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .was-validated .form-control:invalid{
  background-image: unset;
}

.btn-login{
  color: #FFF;
  text-align: center;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 8px;
  background: var(--C-01, #2DC76D);
  padding: 11px;
  border:1px solid #2DC76D;
}
.alink-st-1{
  color: var(--Font, #2F4858);
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.form-check-st-1 .form-check-label{
  color: var(--Font, #2F4858);
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form-check-st-1 .form-check-input{
  width: 24px;
  height: 24px;
  margin-top: 0.05em;
  margin-right: 10px;
}
.form-check-st-1 .form-check-input:checked{

  background-color: var(--C-01, #2DC76D);
  border-color: #2DC76D;
}
.btn-login-google{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M32.6499 16.8763C32.6499 15.7417 32.546 14.6508 32.3531 13.6036H16.9764V19.7926H25.7631C25.3846 21.7926 24.2343 23.4872 22.5052 24.6217V28.6363H27.7816C30.8688 25.8508 32.6499 21.749 32.6499 16.8763Z" fill="%234285F4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.9765 32.5125C21.3846 32.5125 25.0803 31.0799 27.7816 28.6363L22.5052 24.6217C21.0432 25.5817 19.1731 26.1489 16.9765 26.1489C12.7241 26.1489 9.12487 23.3343 7.84101 19.5525H2.38646V23.698C5.07292 28.9271 10.5943 32.5125 16.9765 32.5125Z" fill="%2334A853"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.84101 19.5525C7.51448 18.5925 7.32894 17.5672 7.32894 16.5126C7.32894 15.4581 7.51447 14.4326 7.841 13.4726V9.32719H2.38645C1.2807 11.4872 0.649902 13.9308 0.649902 16.5126C0.649902 19.0944 1.28071 21.538 2.38646 23.698L7.84101 19.5525Z" fill="%23FBBC05"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.9765 6.8762C19.3735 6.8762 21.5256 7.68347 23.2176 9.26893L27.9004 4.67984C25.0729 2.09803 21.3772 0.512573 16.9765 0.512573C10.5943 0.512573 5.07291 4.09811 2.38645 9.32719L7.841 13.4726C9.12486 9.69082 12.7241 6.8762 16.9765 6.8762Z" fill="%23EA4335"/></svg>');
  background-repeat: no-repeat;
  background-position: left 11px center;
  background-color: transparent;
  border-radius: 4px;
  background-color: var(--White, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border:0;
  color: var(--Grey, #3C4043);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px 8px;
  padding-left: 65px;
  text-align: left;
}
.btn-login-google.login{
  background-position: right 11px center;
  display: flex;
  align-items: center;
  padding: 10px 8px;
  padding-left: 15px;
  padding-right: 65px;

}
.btn-login-google.login .picture{
  width: 39.81px;
  flex:0 0 39.81px;
  height: 39.81px;
  margin-right: 20px;
}
.btn-login-google.login .picture img{
  width: 39.81px;
  height: 39.81px;
  border-radius: 50%;
  object-fit: cover;
}
.btn-login-google.login .name{
  text-overflow: ellipsis;
  white-space:no-wrap;
  overflow: hidden;
  color: var(--font, #061126);
  font-family: Sarabun;
  font-size: 14.54px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.btn-login-google.login .email{
  color: var(--Grey, #3C4043);
  font-family: Sarabun;
  font-size: 12.177px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.028px;
  text-align: left;
}



.pb-05{
  padding-bottom:2px;
}

.btn-back-st-1{
  color: var(--font, #061126);
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M30 16C30 13.2311 29.1789 10.5243 27.6406 8.22202C26.1022 5.91973 23.9157 4.12531 21.3576 3.06569C18.7994 2.00606 15.9845 1.72881 13.2687 2.26901C10.553 2.8092 8.05844 4.14257 6.10051 6.1005C4.14257 8.05844 2.8092 10.553 2.26901 13.2687C1.72881 15.9845 2.00606 18.7994 3.06569 21.3576C4.12532 23.9157 5.91973 26.1022 8.22202 27.6406C10.5243 29.1789 13.2311 30 16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16ZM24 17L11.85 17L17.43 22.607L16 24L8 16L16 8L17.43 9.427L11.85 15L24 15L24 17Z" fill="%232DC76D"/></svg>');
  background-color: transparent;
  border: 0;
  background-repeat: no-repeat;
  background-position: left 0px center ;
  padding: 15px 0;
  padding-left: 50px;
  text-decoration: none;
}

.card-register-st-1{
  border:0;
  max-width: 564px;
  margin:0 auto;
  width: 100%;
  height: 493px;

  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(76, 78, 100, 0.22);

}
.card-register-st-1 .card-body{
  padding-left: 80px;
  padding-right: 80px;
}

.form-st-2{
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
}
.form-st-2 input,
.form-st-2 select{
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(76, 78, 100, 0.22);
  background: rgba(76, 78, 100, 0.04);
  background-color: #FFF;
}
.form-st-2 label{
  color:rgba(76, 78, 100, 0.68);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.15px;
  position: absolute;
  top: 0;
  left: 10px;
  margin-top: -7.5px;

  background: linear-gradient(#FFF 60%, transparent);
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
}

.form-st-2 select{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7 10L12 15L17 10H7Z" fill="%234C4E64" fill-opacity="0.54"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.select-st-1 .css-13cymwt-control{
  padding: 0px;
  border-radius: 8px;
  border: 1px solid rgba(76, 78, 100, 0.22);
  background: rgba(76, 78, 100, 0.04);
  background-color: #FFF;
}
.select-st-1  .css-1fdsijx-ValueContainer{
  padding: 5px;
}

.select-st-1 .css-1n6sfyn-MenuList{
  z-index: 2;

}
.select-st-1  .css-1nmdiq5-menu,
.select-st-1  .css-1nmdiq5-menu{

  z-index: 2;
}
.select-st-1  .css-1u9des2-indicatorSeparator{
  display: none;
}



.btn-st-2{
  color: var(--font, #061126);
  text-align: center;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  width: 175px;
  padding: 9px 12px;
  border-radius: 8px;
  border: 1px solid  #B6BBC6;
  background-color: transparent;
}

.btn-select-business{
  border-radius: 4.726px;
  background-color:  #FFF;
  box-shadow: 0px 4.72581px 4.72581px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  width: 293px;
  padding: 9.452px;
  align-items: center;
  gap: 18.903px;
  padding-right: 25px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path d="M7.67656 6.49967C7.67656 6.6375 7.65687 6.77022 7.61749 6.89782C7.57811 7.0262 7.49935 7.13962 7.3812 7.23808L2.06467 12.5841C1.88745 12.7417 1.6807 12.8204 1.44441 12.8204C1.20812 12.8204 1.00136 12.7318 0.824145 12.5546C0.666618 12.3774 0.587853 12.1706 0.587853 11.9343C0.587853 11.6981 0.666618 11.4913 0.824145 11.3141L5.63856 6.49967L0.824144 1.65572C0.646926 1.49819 0.563043 1.30128 0.572495 1.06499C0.582734 0.8287 0.666617 0.621946 0.824144 0.444729C1.00136 0.267511 1.21284 0.178902 1.45858 0.178902C1.70511 0.178902 1.90714 0.267511 2.06467 0.444729L7.3812 5.76126C7.49935 5.85972 7.57811 5.97274 7.61749 6.10034C7.65687 6.22872 7.67656 6.36183 7.67656 6.49967Z" fill="%233C4043"/></svg>');
  background-position: right 15px center;
  background-repeat: no-repeat;
}
.btn-select-business:hover{
  border: 1px solid #061126;
}
.btn-select-business .business-logo{
  width: 49.81px;
  flex:0 0 49.81px;
  height: 49.81px;
}
.btn-select-business .business-logo img{
  width: 49.81px;
  height: 49.81px;
  border-radius: 50%;
  object-fit: cover;
}
.btn-select-business .business-name{
  text-overflow: ellipsis;
  white-space:no-wrap;
  overflow: hidden;
  color: var(--font, #061126);
  font-family: Sarabun;
  font-size: 16.54px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.btn-select-business .business-level{
  color: var(--Grey, #3C4043);
  font-family: Sarabun;
  font-size: 14.177px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.028px;
  text-align: left;
}
.btn-add-business{
  border-radius: 4.726px;
  border: 1px dashed var(--C-01, #2DC76D);
  background: var(--foundation-green-light, #EAF9F0);
  display: flex;
  width: 293px;
  padding: 6.452px 33px;
  align-items: center;
}
.btn-add-business:hover{
  border: 1px dashed var(--C-01, #2DC76D);
  background: #d2f5e1;
 
}
.btn-add-business .icon-plus{
  width: 49.81px;
  height: 49.81px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none"><path d="M19.125 13.8008C19.125 13.1795 18.6213 12.6758 18 12.6758C17.3787 12.6758 16.875 13.1795 16.875 13.8008L16.875 17.1758H13.5C12.8787 17.1758 12.375 17.6795 12.375 18.3008C12.375 18.9221 12.8787 19.4258 13.5 19.4258H16.875V22.8008C16.875 23.4221 17.3787 23.9258 18 23.9258C18.6213 23.9258 19.125 23.4221 19.125 22.8008L19.125 19.4258H22.5C23.1213 19.4258 23.625 18.9221 23.625 18.3008C23.625 17.6795 23.1213 17.1758 22.5 17.1758H19.125V13.8008Z" fill="%232DC76D"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18 2.17578C9.09441 2.17578 1.875 9.39519 1.875 18.3008C1.875 27.2064 9.09441 34.4258 18 34.4258C26.9056 34.4258 34.125 27.2064 34.125 18.3008C34.125 9.39519 26.9056 2.17578 18 2.17578ZM4.125 18.3008C4.125 10.6378 10.337 4.42578 18 4.42578C25.663 4.42578 31.875 10.6378 31.875 18.3008C31.875 25.9637 25.663 32.1758 18 32.1758C10.337 32.1758 4.125 25.9637 4.125 18.3008Z" fill="%232DC76D"/></svg>');
  background-position: left center;
  background-repeat: no-repeat;
}



.loginpage{
	height: 100vh;
	width: 100%;
	background-position: center center;
	background-size:cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-login{
	width: 448px;

	padding: 20px 30px;
	background: #FFFFFF;
  padding-bottom: 10px;

	border-radius: 25px;
	background: #FFF;
}
.box-login .logo{
	width: 210px;
	height: 210px;
	margin: 0 auto;
}